.wait-passenger-container {
  padding-bottom: 0.2rem;
  .wait-tip-wrap{
    display: flex;
    img{
      width: 0.16rem;
      height: 0.16rem;
      margin-right: 0.07rem;
    }
  }
  h1 {
    font-size: 0.15rem;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.9);
    i{
      color: #0068eb;
      font-style: normal;
      padding: 0 0.02rem;
    }
  }
  .content {
    padding-top: 0.16rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .hideNum{
      color: rgba(0, 0, 0, 0.3);
      font-size: 0.13rem;
      font-weight: 500;
      padding-right: 0.05rem;
    }
    & > div {
      font-size: 0.13rem;
      line-height: 0.13rem;
      font-weight: 400;
      color: #414A5A;
      &::before {
        font-size: 0.09rem;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        padding-right: 0.03rem;
      }
    }
    .phone::before {
      content: '上车站点:';
    }
    .address{
      margin-top: 0.1rem;
    }
    .address::before {
      content: '下车站点:';
    }
    .upSation,.downSation{
      label {
        font-size: .12rem;
        color: rgba(59, 76, 103, 0.6);
        margin-right: 0.09rem;
      }
      span{
        font-size: .12rem;
        color: #1A1A1A;
      }
    }
    .downSation{
      margin-top: 0.09rem;
    }
  }
    .TopContent {
        display: flex;
        // justify-content: space-between;
        .TopButton {
            width: 1.40rem;
            height: 0.38rem;
            background: #0068EB;
            border-radius: 0.03rem;
            font-weight: 500;
            font-size: 0.13rem;
            line-height: 0.38rem;
            color: #FFFFFF;
            text-align: center;
        }
        .ContentRight {
            display: flex;
            margin-left: 0.12rem;
            align-items: center;
            .phone,.Arravietime {
              display: flex;
              align-items: center;
            }
        }
        .Arravietime {
            margin-left: 0.1rem;
        }
        .Number {
            font-weight: bold;
            font-size: 0.19rem;
            line-height: 0.22rem;
            color: #0068EB;
            font-family: 'DIN Alternate';
        }
        .outNumber {
            font-weight: bold;
            font-size: 0.19rem;
            line-height: 0.22rem;
            color: #F67D2B;
            font-family: 'DIN Alternate';
        }
        .RightLabel {
            font-weight: bold;
            font-size: 0.11rem;
            line-height: 0.21rem;
            margin-right: 0.03rem;
            color: #414758;
        }
    }

  .mtip{
    color: #FFD290!important;
  }
}
