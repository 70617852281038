.Container {
    padding: 0 0.3rem 0.2rem 0;
}
.Content {
    display: flex;
    flex-direction: column;
}
.TimeTips{
    font-size: 0.17rem;
    color: rgba(0, 0, 0, 0.9);
    font-weight: bold;
    margin-bottom: 0.1rem;
    padding-top: .1rem;
}
.Time{
   color: rgba(0, 104, 235, 1);
   padding: 0 0.05rem;
}
.ArriveTips{
    font-size: 0.15rem;
    color: rgba(0, 0, 0, 0.8);
    padding: 0.1rem 0 0;
}
.Arrive{
    font-size: 0.13rem;
    padding: 0 0.01rem;
    color: rgba(0, 104, 235, 1);
    font-weight: 500;
}
.GetPassengerImage{
    width: 0.2rem;
    height: 0.18rem;
    margin-right: 0.07rem;
}
.newVersionTips{ 
    font-size: 0.14rem;
    font-weight: 500;
    display: flex;
    align-items: center;
}
.GetBtn {
    // width: 2.89rem;
    height: 0.48rem;
    line-height: 0.48rem;
    background: #0068EB;
    font-size: 0.16rem;
    font-weight: 400;
    color: #fff;
    text-align: center;
    margin: 0.14rem auto 0;
    border-radius: 0.04rem;
}
.MtourColor{
    color: #0068EB;
}

.MtourInfo{
    display: flex;
    flex-direction: column;
    margin-top: 0.15rem;
}

.MLabel{
    font-size: .12rem;
    font-weight: 400;
    line-height: 0.17rem;
    color: rgba(59, 76, 103, 0.6);
    margin-right: 0.09rem;
}
.MContent {
    font-size: .12rem;
    font-weight: 400;
    line-height: 0.17rem;
    color: #1A1A1A;
}

.MMT {
    margin-top: 0.08rem;
}

.TopContent {
    display: flex;
    // justify-content: space-between;
}
.TopButton {
    width: 1.40rem;
    height: 0.38rem;
    background: #0068EB;
    border-radius: 0.03rem;
    font-weight: 500;
    font-size: 0.13rem;
    line-height: 0.38rem;
    color: #FFFFFF;
    text-align: center;
}
.ContentRight {
    display: flex;
    margin-left: 0.06rem;
    align-items: center;
    text-align: center;
}
.Duration {
    margin-left: 0.08rem;
}
.Arravietime {
    margin-left: 0.1rem;
}
.Number {
    font-weight: bold;
    font-size: 0.19rem;
    line-height: 0.22rem;
    color: #0068EB;
    font-family: 'DIN Alternate';
    margin-right: 0.03rem;
}
.RightLabel {
    font-weight: bold;
    font-size: 0.11rem;
    line-height: 0.21rem;
    color: #414758;
    white-space: nowrap;
}