.order-card {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  z-index: 200;
  width: 3.7rem;
//   padding: 0.2rem 0.34rem 0;
  padding: 0.15rem 0.12rem 0;
  background: #fff;
  box-shadow: 0rem 0.02rem 0.08rem 0rem rgba(0, 0, 0, 0.1);
  border-radius: 0.03rem 0.03rem 0rem 0rem;
//   border-radius: 0.23rem 0.23rem 0rem 0rem;
  .cross-label{
    position: absolute;
    right: 0;
    top: -0.11rem;
    z-index: 999;
    font-size: 0.09rem;
    line-height: 0.15rem;
    width: 0.66rem;
    height: 0.25rem;
    text-align: center;
    color: #fff;
    border-radius: 0.03rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  transition: bottom 0.3s ease;
  .click-area{
    position: absolute;
      top: 0;
      right: 0;
      width: 55%;
      height: 0.5rem;
    .arrow {
      position: absolute;
      top: .32rem;
      right: 0.15rem;
      width: 0.08rem;
      height: 0.05rem;
      transform: rotate(180deg);
      background: url('~@/assets/img/orderCard/icon-arrow-new.png') 0 0/ 100% no-repeat;
    }
  }
  
  .arrow.up {
    transform: rotate(0);
    transform-origin: center;
  }
  .btn {
    width: 100%;
    height: 0.48rem;
    background: #0068eb;
    border-radius: 0.04rem;
    text-align: center;
    line-height: 0.48rem;
    font-size: 0.16rem;
    color: #ffffff;
    margin-top: 0.16rem;
  }
  .tip {
    position: absolute;
    top: -0.47rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.12rem;
    color: #ffffff;
    padding: 0.1rem 0.14rem;
    background: #191E28;
    // box-shadow: 0rem 0.02rem 0.1rem 0rem rgba(0, 0, 0, 0.06);
    border-radius: 0.19rem;
    font-weight: 500;
    span {
      color: #82E7F8;
      padding-left: 0.03rem;
    }
  }
  .cancel-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 0.62rem;
    .phone {
      font-size: 0.16rem;
      color: rgba(0, 0, 0, 0.9);
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      line-height: 0.18rem;
      &-label{
        font-size: 0.09rem;
        color: #8692A6;
        padding-bottom: 0.08rem;
      }
    }
    span {
      color: #1A1A1A;
      font-weight: 500;
    }
    .cancel-btn {
      width: 0.79rem;
      height: 0.39rem;
      border-radius: 0.06rem;
      border: 0.01rem solid #0068eb;
      font-size: 0.12rem;
      font-weight: 500;
      color: #0068eb;
      text-align: center;
      line-height: 0.39rem;
    }
    
  }
  .hide-box {
    height: 100%;
    .hide-content {
        display: flex;
        padding-bottom: 0.14rem;
        .hide-left {
            width: 1.4rem;
        }
        .hide-right {
            margin-left: 0.12rem;
            color: #414758;
            font-size: 0.11rem;
            .upstation {
                margin-bottom: 0.03rem;
                display: flex;
                line-height: 0.18rem;
            }
            .downstation {
                margin-bottom: 0.03rem;
                display: flex;
                white-space: nowrap;
                line-height: 0.18rem;
            }
            .right-label{
              white-space: nowrap;
            }
            .right-name {
              font-weight: bold;
              white-space: nowrap;
            }
        }
    }
    .cancel-btn-bottom {
        width: 100%;
        height: 0.44rem;
        text-align: center;
        border-top: 0.005rem solid rgba(0, 0, 0, 0.1);
        font-weight: 400;
        font-size: 0.13rem;
        line-height: 0.44rem;
        color: rgba(10, 10, 10, 0.7);
    }
  }
}

.order-card-driveOff {
  padding: 0.1rem 0.34rem 0;
}
