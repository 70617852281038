
.order-list-mask{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 201;
    background: rgba(0,0,0,.3);
}

.order-list-wrapper{ 
    position: absolute;
    top: 0.16rem;
    left: 0.6rem;
    width: 3.1rem;
    height: 4.9rem;
    background: #fff;
    z-index: 301;
    border-radius: .05rem;
}

.order-list-header{
    height: 0.59rem;
    display: flex;
    .order-close-wrap{
        width: 0.45rem;
        height: 0.45rem;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: .24rem;
            height: .24rem;
        }
    }
}

.order-list{
    padding: 0 .2rem;
    height: 4rem;
    overflow: scroll;
}

.order-list::-webkit-scrollbar {display: none;}

.order-item{
    width: 100%;
    background: #FAFAFA;
    border-radius: 6px;
    padding: 0.24rem;
    margin-bottom: .1rem;
}

.item-title{
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 0.07rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: .1rem;
}

.order-type {
    font-size: .13rem;
    font-weight: 500;
}

.order-status{
    font-size: .12rem;
    color:rgba(0, 104, 235, 1)
}

.list-item{
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: .11rem;
    line-height: .27rem;
    label {
        color: rgba(0, 0, 0, 0.6);
        padding-right: 0.03rem;
    }
}

.cancel-btn{
    width: 1.09rem;
    height: .34rem;
    text-align: center;
    line-height: .34rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-size: .12rem;
    margin-top: .11rem;
}

.order-empty {
    font-size: 0.12rem;
    text-align: center;
    height: 100%;
    padding-top: 1rem;
    color: rgba(10, 10, 10, 0.7);
}
