.Container {
    position: absolute;
    z-index: 161;
    width: 3.1rem;
    height: 92%;
    background: #fff;
    top: 0.16rem;
    left: 0.65rem;
    margin: auto;
    border-radius: 0.15rem;
}
.OrderStatus{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0.8rem;
}
.IconInfo{
    width: .4rem;
    height: .4rem;
}
.TextInfo{
    margin-top: 0.2rem;
    color: rgba(0, 0, 0, 0.9);
    font-size: 0.18rem;
    font-weight: bold;
}
.OrderInfo{
    width: 90%;
    background: #FAFAFA;
    margin: 0.4rem auto 0;
    display: flex;
    flex-direction: column;
    padding: 0.22rem 0.34rem;
    border-radius: 0.06rem;
}
.InfoItem{
    display: flex;
    align-items: center;
}
.InfoItemLabel{
    font-size: 0.11rem;
    color: #00000099;
    margin-right: 0.12rem;
    white-space: nowrap;
}
.InfoItemText{
    font-size: .13rem;
    color: rgba(0, 0, 0, 0.9);
    padding: .1rem 0;
    flex: 1;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    white-space: nowrap;
}
.BorderNone {
    border-bottom: none;
}
.Btn{
    width: 2.89rem;
    height: 0.48rem;
    background: rgba(0, 104, 235, 1);
    border-radius: 0.04rem;
    text-align: center;
    color: #fff;
    line-height: 0.48rem;
    font-size: 0.16rem;
    position: absolute;
    bottom: 0.16rem;
    z-index: 162;
    left: 0;
    right: 0;
    margin: 0 auto;
}
.addressBox{
    width: 2.64rem;
    height: 1.34rem;
    background: #FAFAFA;
    margin: 0.39rem auto 0;
    border-radius: 0.06rem;
}
.station{
    padding: 0.16rem 0;
    margin: 0 0.22rem 0 0.22rem;
}
.stationLabel{
    font-size: 0.11rem;
    line-height: .15rem;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 0.02rem;
}
.stationParag{
    font-size: .13rem;
    line-height: .18rem;
    color: #1A1A1A;
}
.up {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}