@font-face {
  font-family: "iconfont"; /* Project id 3163312 */
  src: url('iconfont.woff2?t=1648113920830') format('woff2'),
       url('iconfont.woff?t=1648113920830') format('woff'),
       url('iconfont.ttf?t=1648113920830') format('truetype'),
       url('iconfont.svg?t=1648113920830#iconfont') format('svg');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-denglu:before {
  content: "\e606";
}

.icon-zhanghao:before {
  content: "\e607";
}

.icon-weidenglu:before {
  content: "\e608";
}

.icon-dingwei:before {
  content: "\e609";
}

.icon-xinxi:before {
  content: "\e63e";
}

.icon-ditu:before {
  content: "\e63f";
}

.icon-zhanghu:before {
  content: "\e640";
}

.icon-dingdan:before {
  content: "\e641";
}

