.wrapper {
  display: flex;
  height: 100vh;
  overflow: hidden;
}
.main {
  position: relative;
  min-width: 100%;
  width: 100%;
  height: 100%;
  /* margin-left: 0.68rem; */
}
body {
  user-select: none;
}
