.drive-off-container {
  padding-bottom: 0.2rem;
  .trip-info {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 0.6rem;
    background: #fff;
    border-bottom: 0.01rem solid rgba(0, 0, 0, 0.1);
    .trip-item {
      position: relative;
      flex: 1;
      text-align: center;
      .count {
        font-size: 0.14rem;
      }
      span {
        font-size: 0.2rem;
        color: #1A1A1A;
      }
      .trip-name {
        padding-top: 0.08rem;
        font-size: 0.1rem;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
    .TopContent {
      display: flex;
      padding-right: 0.3rem;
    // justify-content: space-between;
    }
    .TopButton {
        width: 1.40rem;
        height: 0.38rem;
        background: #0068EB;
        border-radius: 0.03rem;
        font-weight: 500;
        font-size: 0.13rem;
        line-height: 0.38rem;
        color: #FFFFFF;
        text-align: center;
    }
    .ContentRight {
        display: flex;
        margin-left: 0.06rem;
        align-items: center;
        text-align: center;
    }
   
    .Duration {
        margin-left: 0.08rem;
       
    }
    .Arravietime {
        margin-left: 0.1rem;
    }
    .Number {
        font-weight: bold;
        font-size: 0.19rem;
        line-height: 0.22rem;
        color: #0068EB;
        font-family: 'DIN Alternate';
        margin-right: 0.03rem;
        white-space: nowrap;
    }
    .RightLabel {
        font-weight: bold;
        font-size: 0.11rem;
        line-height: 0.21rem;
        color: #414758;
        white-space: nowrap;
    }
  .address-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0.1rem;
    img {
      width: 0.1rem;
      height: 0.12rem;
      margin-right: 0.1rem;
    }
    .address {
      text-align: center;
      font-size: 0.15rem;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.9);
      &::before {
        content: '前往';
        color: rgba(0, 0, 0, 0.6);
        font-size: 0.1rem;
        padding-right: 0.08rem;
      }
    }
  }
  .content {
    padding-top: 0.16rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .upSation,.downSation{
      label {
        font-size: .12rem;
        color: rgba(59, 76, 103, 0.6);
        margin-right: 0.09rem;
      }
      span{
        font-size: .12rem;
        color: #1A1A1A;
      }
    }
    .downSation{
      margin-top: 0.09rem;
    }
  }
}
