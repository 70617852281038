.order-complate-container {
  position: absolute;
  top: 0.16rem;
  left: 0.65rem;
  width: 3.1rem;
  height: 92%;
  background: #fff;
  z-index: 162;
  display: flex;
  flex-direction: column;
  align-items: center;
  .icon {
    margin: 0.8rem 0 0.2rem;
  }
  .tip {
    margin-bottom: 0.2rem;
    font-size: 0.18rem;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.9);
    line-height: 0.25rem;
  }
  .trip-info {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.62rem;
    height: 0.71rem;
    background: #FAFAFA;
    border-radius: 0.06rem;
    margin-bottom: 0.1rem;
    .trip-item {
      position: relative;
      flex: 1;
      text-align: center;
      // color: #1565FF;
      font-weight: 700;
      .count {
        font-size: 0.14rem;
      }
      span {
        font-size: 0.18rem;
        margin-right: 0.03rem;
      }
      .trip-name {
        padding-top: 0.1rem;
        font-size: 0.1rem;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
      }
      &:not(:last-child)::after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        width: 0.01rem;
        height: 0.3rem;
        background: rgba(0, 0, 0, 0.1);
        transform: translateY(-50%) scaleX(0.5);
      }
    }
  }
  .address-box {
    width: 2.62rem;
    background: #FAFAFA;
    border-radius: 0.06rem;
    .station{
      padding: 0.16rem 0;
      margin: 0 0 0 0.22rem;
      label{
        font-size: 0.11rem;
        line-height: .15rem;
        color: rgba(0, 0, 0, 0.6);
        margin-bottom: 0.02rem;
      }
      p{
        font-size: .13rem;
        line-height: .18rem;
        color: #1A1A1A;
      }
    }
    .station.up {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    // padding: 0 0.34rem 0 0.66rem;
    .address-content {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 0.52rem;
      line-height: 0.52rem;
      font-size: 0.13rem;
      color: rgba(0, 0, 0, 0.9);
      position: relative;
      padding-left: 0.8rem;
      padding-right: 0.2rem;
      &:not(:last-child)::after {
        content: '';
        display: block;
        height: 1px;
        background: rgba(0, 0, 0, 0.1);
        transform: scaleY(0.5);
      }
      &::before {
        position: absolute;
        left: 0.2rem;
        top: 0rem;
        font-size: 0.11rem;
        color: rgba(0, 0, 0, 0.6);
        font-weight: 400;
      }
    }
    .start::before {
      content: '上车站点:';
    }
    .end::before {
      content: '下车站点:';
    }
  }
  .btn-box {
    display: flex;
    justify-content: space-between;
    width: 2.62rem;
    height: 0.48rem;
    position: absolute;
    bottom: 0.16rem;
    left: 50%;
    transform: translateX(-50%);
    .btn {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #0068eb;
      color: #ffffff;
      font-size: 0.16rem;
      border-radius: 0.04rem;
      &.border {
        background: transparent;
        border: 1px solid #0068eb;
        color: #0068eb;
      }
      &.disabled {
        background: #e6e6e6;
        border: none;
        color: #fff;
      }
    }
  }
}
