.loading{
  font-size: 0.16rem;
  line-height: 100vh;
  text-align: center;
  width: 100%;
  color: #5B6780;
}
.login-container {
  width: 100%;
  height: 100%;
  display: flex;
  background: #fff;
  select {
    position: fixed;
    top: 1rem;
    left: 1.14rem;
  }
  .content {
    width: 6.3rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 1.14rem;
    padding-right: 1.14rem;
    h1 {
      font-size: 0.3rem;
    }
    h3 {
      padding-top: 0.06rem;
      font-size: 0.2rem;
      font-weight: 350;
      line-height: 0.4rem;
      color: #71767d;
    }
    .input-box {
      position: relative;
      width: 4rem;
      height: 0.6rem;
      margin-top: 0.3rem;
      border-radius: 0.36rem;
      border: 1px solid #0068eb;
      display: flex;
      align-items: center;
      .text {
        font-size: 0.16rem;
        color: #1a1a1a;
        padding-left: 0.33rem;
        &.placeholder{
          color: rgba(0, 0, 0, 0.6);
        }
      }
      
    }

    .tip {
      position: absolute;
      left: 0.33rem;
      bottom: -0.22rem;
      font-size: 0.16rem;
      color: #f6562b;
    }
    input {
      display: block;
      width: 100%;
      height: 100%;
      font-size: 0.16rem;
      color: #1a1a1a;
      padding-left: 0.33rem;
    }
    .car-plate-result{
      display: flex;
      font-size: 0.18rem;
      color: rgba(113, 118, 125, 1);
      line-height: .34rem;
      margin-top: .13rem;
      padding-left: .11rem;
      span {
        padding-left: .1rem;
        font-weight: 400;
        font-size: .2rem;
        color:rgba(24, 24, 24, 1);
      }
    }

    .find-result-tips{
      line-height: .38rem;
      height: .38rem;
      width: 3.72rem;
      margin-top: .09rem;
      background:rgba(255, 244, 237, 1);
      border-radius: .19rem;
      display: flex;
      align-items: center;
      font-size: .17rem;
      color: rgba(246, 125, 43, 1);
      img {
        width: .16rem;
        height: .16rem;
        margin: 0 0.07rem 0 0.11rem;
      }
      .retry-btn{
        font-weight: 500;
        padding-left: .07rem;
        text-decoration: underline;
      }
    }
    .btn {
      margin-top: 0.35rem;
      width: 4rem;
      height: 0.6rem;
      text-align: center;
      line-height: 0.6rem;
      background: #0068eb;
      border-radius: 0.36rem;
      color: #fff;
      font-size: 0.2rem;
      &.disabled {
        opacity: 0.39;
      }
    }
  }
  .bg {
    flex: 1;
    background: url('~@/assets/img/login/bg.png') no-repeat center center / 100% 100%;
  }

  .reload{
    text-decoration: underline;
    padding-left: .05rem;
  }
}