.route-tips {
    position: absolute;
    top: 2.85rem;
    left: 50%;
    transform: translateX(-50%);
    width: 1.32rem;
    height: 0.37rem;
    box-shadow: 0rem 0.02rem 0.1rem 0rem rgba(0, 0, 0, 0.06);
    border-radius: 0.19rem;
    color: #fff;
    font-size: 0.12rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;   
    &-success{
        background: #07C160;
    }
    &-doing{
        background: #1b48c5;
    }
    &-fail{
        background: #FF3737;
    }
    .spinner {
        width: 0.18rem;
        height: 0.18rem;
        margin-right: 0.12rem;
        &-doing {
            background: url('~@/assets/img/routeLoading/spinner.png') no-repeat 0 0 / 100%;
        animation: rotate 1s linear infinite;
        }
        &-success {
            background: url('~@/assets/img/routeLoading/routeSuccess.png') no-repeat 0 0 / 100%;
        }
        &-fail {
            background: url('~@/assets/img/routeLoading/routeFail.png') no-repeat 0 0 / 100%;
        }
    }
    @keyframes rotate {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
}
  