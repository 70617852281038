
.mtour-coming-tips{
    width: 2.56rem;
    height: .8rem;
    position: absolute;
    left: 0;
    right: 0;
    top: .18rem;
    margin: auto;
    border-radius: .1rem;
    background: url(./orderTips.png) 0 0/100% 100% no-repeat;
    
    .tips-wrapper{
        height: .7rem;
        display: flex;
        align-items: center;
        justify-content: center;
        .order-icon {
            width: .4rem;
            height: .4rem;
        }
        span {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 500;
            font-size: .16rem;
            line-height: .22rem;
            padding-left: .1rem;
            background-image: -webkit-gradient(linear, left 0, right 0, from(rgba(98, 144, 232, 1)), to(rgba(106, 159, 209, 1)));
            -webkit-background-clip: text; 
            -webkit-text-fill-color: transparent; 
        }
    }
}

.today-order-count {
    width: 2.56rem;
    height: 1.06rem;
    position: absolute;
    left: 0;
    right: 0;
    top: .18rem;
    margin: auto;
    border-radius: .1rem;
    background: url(./todayOrderTips.png) 0 0/100% 100% no-repeat;
    .today-count-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        p{
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 500;
            font-size: .16rem;
            background-image: -webkit-gradient(linear, left 0, right 0, from(rgba(115, 151, 220, 1)), to(rgba(156, 154, 239, 1)));
            -webkit-background-clip: text; 
            -webkit-text-fill-color: transparent; 
            text-align: center;
            margin-top: .2rem;
        }
        span{
            width: 2.3rem;
            height: .34rem;
            background: rgba(255, 255, 255, 0.8);
            border-radius: .32rem;
            text-align: center;
            line-height: .34rem;
            margin-top: .13rem;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 500;
            font-size: .12rem;
            i{
                background-image: -webkit-gradient(linear, left 0, right 0, from(rgba(98, 144, 232, 0.66)), to(rgba(106, 159, 209, 0.66)));
                -webkit-background-clip: text; 
                -webkit-text-fill-color: transparent; 
                font-style: normal;
            }
        }
    }
}

.order-start-tips{
    width: 2.57rem;
    height: .87rem;
    position: absolute;
    left: 0;
    right: 0;
    top: .18rem;
    margin: auto;
    border-radius: .1rem;
    background: url(./orderStartTips.png) 0 0/100% 100% no-repeat;
    display: flex;
    flex-direction: column;
    align-items: center;
    .start-tips-wrapper{
        width: 1.7rem;
        text-align: left;
        margin-top: 0.23rem;
        line-height: .16rem;
        padding-left: 0.18rem;
        display: flex;
        flex-direction: column;
        span {
            font-size: .14rem;
            color: rgba(255, 142, 63, 1);
            font-weight: 500;
        }
    }
}
