.Mask{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .3);
    z-index: 201;
}

.Container{ 
    position: absolute;
    top: 0.16rem;
    left: 0.6rem;
    width: 3.1rem;
    height: 92%;
    background: #fff;
    z-index: 301;
    border-radius: .05rem;
}

.Close {
    position: absolute;
    left: 0rem;
    top: 0rem;
    width: 0.45rem;
    height: 0.45rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.CloseIcon {
    width: .24rem;
    height: .24rem;
}

.UserInfo {
    width: 2rem;
    height: 1.5rem;
    position: absolute;
    top: 1.07rem;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.UserAvata{
    width: 0.76rem;
    height: 0.76rem;
    position: relative;
}

.crown {
    position: absolute;
    top: -0.09rem;
    left: 0.48rem;
    width: 0.3rem;
    height: 0.3rem;
    transform: rotate(-4deg);
    background: url(../../../assets/crown.png) 0 0/cover no-repeat;
}

.UserText{
    font-size: .20rem;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.9);
    padding-top: 0.08rem;
}

.Flag{
    width: 0.88rem;
    height: 0.24rem;
    background: url(../../../assets/mtour_flag.png) 0 0/cover no-repeat;
    color: #FFD290;
    line-height: .24rem;
    font-size: .1rem;
    text-align: center;
    margin-top: 0.04rem;
    font-weight: 600;
}

.LoginOut {
    color: #fff;
    border-radius: 0.04rem;
    border: 1px solid #0068EB;
    background: rgba(0, 104, 235, 1);
    width: 2.84rem;
    height: .48rem;
    text-align: center;
    line-height: 0.48rem;
    position: absolute;
    bottom: 0.59rem;
    font-size: 0.16rem;
    left: 0;
    right: 0;
    margin: 0 auto;
}