.route-loading {
  position: absolute;
  top: 2.85rem;
  left: 50%;
  transform: translateX(-50%);
  width: 1.32rem;
  height: 0.37rem;
  background: #1b48c5;
  box-shadow: 0rem 0.02rem 0.1rem 0rem rgba(0, 0, 0, 0.06);
  border-radius: 0.19rem;
  color: #fff;
  font-size: 0.12rem;
  display: flex;
  align-items: center;
  justify-content: center;
  .spinner {
    width: 0.18rem;
    height: 0.18rem;
    background: url('~@/assets/img/routeLoading/spinner.png') no-repeat 0 0 / 100%;
    margin-right: 0.12rem;
    animation: rotate 1s linear infinite;
  }
  @keyframes rotate {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
