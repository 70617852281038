
.Container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #F7F7F7;
}

.Dymic{
    margin-right: 0.05rem;
}

.Num {
    font-style: normal;
    font-weight: 500;
}
