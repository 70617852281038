
.Mask{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    z-index: 1000;
}

.Container {
    position:  absolute;;
    left: 0;
    right: 0;
    top: 50%;
    margin: auto;
    transform: translateY(-50%);
    width: 2.4rem;
    height: 1.24rem;
    background: #fff;
    border-radius: 0.08rem;
    display: flex;
    flex-direction: column;
    z-index: 999;
}

.Content{
    height: 0.88rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #353535;
    font-size: 0.14rem;
    font-weight: 500;
}

.Btns{
    height: 0.5rem;
    display: flex;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.Btn {
    flex: 1;
    text-align: center;
    line-height: 0.5rem;;
    font-size: 0.14rem;
}

.Ok {
    color: #0068EB;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
}