
.mtour-accept-wrapper{
    display: flex;
    align-items: center;
    margin-bottom: .2rem;
}

.get-btn {
    width: 1.40rem;
    height: 0.38rem;
    background: #0068EB;
    border-radius: 0.03rem;
    font-weight: 500;
    font-size: 0.13rem;
    line-height: 0.38rem;
    color: #FFFFFF;
    text-align: center;
}

.get-tip {
    font-family: PingFang SC;
    font-size: .17rem;
    font-weight: 500;
    line-height: .24rem;
    letter-spacing: 0px;
    text-align: left;
    padding-left: .12rem;
}